import React, { useState, useEffect, useMemo } from "react";
import {
  List,
  Typography,
  Space,
  Spin,
  Card,
  Tag,
  Select,
  Avatar,
  Modal,
  Table,
  Button,
  Input,
  Divider,
  message,
} from "antd";
import {
  TrophyOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { readUsers, resetScoresByBranch, scanAllUsers } from "../utils/user";
import { getRank } from "../utils/rank";
import { branchOptions } from "../constants/branch";
import { UserOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";

const { Title, Text } = Typography;
const { Option } = Select;

interface User {
  id: string;
  username: string;
  nickname: string;
  profileImage: string;
  score: number;
  branch: string;
}

const PAGE_SIZE = 20;

export default function Ranking() {
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [selectedBranch, setSelectedBranch] = useState<string>(
    userInfo.branch || ""
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [rootResetBranch, setRootResetBranch] = useState(userInfo.branch);
  const [isReseting, setIsReseting] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = getFilteredUsers();
    setDisplayedUsers(filtered.slice(0, PAGE_SIZE));
    setCurrentPage(1);
  }, [allUsers, selectedBranch, searchTerm]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const result = await scanAllUsers();
      if (result.success && result.users) {
        setAllUsers(
          result.users.filter((i) => {
            return i.isApproved;
          }) || []
        );
      } else {
        console.error("Failed to fetch users:", result.error);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  const getFilteredUsers = () => {
    let filtered = allUsers.filter(
      (user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // filtered = filtered.filter(
    //   (i: any) =>
    //     i.score?.[selectedBranch === "전체" ? i.branch : selectedBranch]
    // );

    return filtered.sort((a, b) => {
      const scoreA =
        a.score?.[selectedBranch === "전체" ? a.branch : selectedBranch] || 0;
      const scoreB =
        b.score?.[selectedBranch === "전체" ? b.branch : selectedBranch] || 0;
      return scoreB - scoreA;
    });
  };

  const loadMoreUsers = () => {
    const filtered = getFilteredUsers();
    const nextPage = currentPage + 1;
    const nextUsers = filtered.slice(0, nextPage * PAGE_SIZE);
    setDisplayedUsers(nextUsers);
    setCurrentPage(nextPage);
  };

  const handleBranchChange = (value: string) => {
    setSelectedBranch(value);
    setSearchTerm("");
  };

  const getRankColor = (index: number) => {
    switch (index) {
      case 0:
        return "#FFD700"; // Gold
      case 1:
        return "#C0C0C0"; // Silver
      case 2:
        return "#CD7F32"; // Bronze
      default:
        return "#555555"; // Default gray
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleUserClick = (userId: string) => {
    const width = 600;
    const height = 800;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `/user-rank?id=${userId}`,
      "userEditPopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const rankingInfoColumns = [
    {
      title: "점수",
      dataIndex: "score",
      key: "score",
      render: (v: any, record: any) => {
        return v;
      },
    },
    {
      title: "등급",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "혜택",
      dataIndex: "benefit",
      key: "benefit",
    },
  ];

  const currentMonth = new Date().toLocaleString("default", {
    month: "2-digit",
    year: "2-digit",
  });

  const rankingInfoData = [
    { key: "1", score: "5점~", rank: "유티지", benefit: "3 DP" },
    { key: "2", score: "20점~", rank: "미들포지션", benefit: "7 DP" },
    { key: "3", score: "50점~", rank: "하이잭", benefit: "15 DP" },
    { key: "4", score: "100점~", rank: "컷오프", benefit: "25 DP" },
    { key: "5", score: "200점~", rank: "버튼", benefit: "35 DP" },
  ];

  const resetScores = async () => {
    setIsReseting(true);
    try {
      const result = await resetScoresByBranch(rootResetBranch);

      if (result.success) {
        message.success("랭킹점수가 초기화되었습니다.");
        fetchUsers();
      } else {
        message.error(
          "랭킹점수 초기화에 실패했습니다. 관리자에게 문의해주세요."
        );
      }
    } catch (error) {
      console.error("Error resetting scores:", error);
      message.error("랭킹점수 초기화 중 오류가 발생했습니다");
    }
    setIsReseting(false);
  };

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const handleResetOk = async () => {
    await resetScores(); 
    setIsResetModalVisible(false);
  };

  const handleResetCancel = () => {
    setIsResetModalVisible(false);
  };

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      />
    );
  }

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        padding: "24px",
        width: "100%",
        maxWidth: 1200,
        boxSizing: "border-box",
        margin: "0 auto",
        gap: 5,
      }}
    >
      <Space
        direction="horizontal"
        align="center"
        style={{
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <Space align="center">
          <Title level={2} style={{ marginTop: 0, marginBottom: 0 }}>
            랭킹
          </Title>
          <Button
            type="text"
            icon={<QuestionCircleOutlined />}
            onClick={showModal}
            style={{ marginLeft: 0, marginTop: 4, color: "#888" }}
          />
          {(userInfo.isAdmin || userInfo.isPointAdmin) && (
            <Button
              icon={<UndoOutlined style={{ transform: "rotate(90deg)" }} />}
              onClick={showResetModal}
              style={{ marginLeft: 0, marginTop: 4, color: "#888" }}
            ></Button>
          )}
        </Space>
        <Space>
          <Text strong style={{ marginLeft: 10 }}>
            {currentMonth}
          </Text>
          <Select
            style={{ width: 100 }}
            value={selectedBranch}
            onChange={handleBranchChange}
          >
            <Option key="전체" value="전체">
              전체
            </Option>
            {branchOptions.map((option) => (
              <Option key={option.label} value={option.label}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Space>
      </Space>
      <Input
        placeholder="사용자 검색"
        prefix={<SearchOutlined />}
        onChange={handleSearchChange}
        style={{ marginBottom: 0, marginTop: 15 }}
      />
      <Divider style={{ margin: "10px 0 0", background: "#272727" }} />

      <List
        dataSource={displayedUsers}
        renderItem={(item: any, index) => (
          <List.Item
            key={item.id}
            onClick={() => {
              if (!userInfo.isAdmin) return;
              handleUserClick(item.id);
            }}
          >
            <Card style={{ width: "100%" }}>
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <Space>
                  <Avatar
                    icon={<UserOutlined />}
                    src={
                      item.profileImage
                        ? `${process.env.REACT_APP_S3_STORAGE}/${
                            item.profileImage
                          }?t=${new Date()}`
                        : ""
                    }
                    size={64}
                    style={{ marginRight: 15 }}
                  />
                  <Space direction="vertical" size={0} style={{ gap: 3 }}>
                    <Text strong>{item.nickname}</Text>
                    <Text type="secondary">{item.username}</Text>
                    <Text type="secondary">
                      <EnvironmentOutlined />{" "}
                      {branchOptions.find((b) => b.value === item.branch)
                        ?.label || item.branch}
                    </Text>
                  </Space>
                </Space>
                <Space
                  direction="vertical"
                  style={{ gap: 3, alignItems: "end" }}
                >
                  <Text
                    strong
                    style={{ fontSize: "14px", color: getRankColor(index) }}
                  >
                    #{index + 1}
                  </Text>
                  <Text strong>
                    {item.score?.[
                      selectedBranch === "전체" ? item.branch : selectedBranch
                    ] || 0}{" "}
                    점
                  </Text>
                  {getRank(item.score?.[item.branch] || 0) && (
                    <Tag
                      color="blue"
                      icon={<TrophyOutlined />}
                      style={{ marginRight: 0 }}
                    >
                      {getRank(item.score?.[item.branch] || 0)}
                    </Tag>
                  )}
                </Space>
              </Space>
            </Card>
          </List.Item>
        )}
        loadMore={
          displayedUsers.length < getFilteredUsers().length ? (
            <div
              style={{
                textAlign: "center",
                marginTop: 12,
                height: 32,
                lineHeight: "32px",
              }}
            >
              <Button onClick={loadMoreUsers}>더 보기</Button>
            </div>
          ) : null
        }
      />
      <Modal
        title="월간 랭킹 정보"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleOk}>
            닫기
          </Button>,
        ]}
      >
        <Table
          columns={rankingInfoColumns}
          dataSource={rankingInfoData}
          pagination={false}
          style={{ background: "transparent" }}
        />
        <Text style={{ marginTop: 16, display: "block", color: "#888" }}>
          - 달성한 다음달 1일~7일 이내에 방문시 적립 가능
        </Text>
      </Modal>
      <Modal
        title="랭킹 초기화"
        open={isResetModalVisible || isReseting}
        onCancel={() => setIsResetModalVisible(false)}
        footer={null}
      >
        {isReseting ? (
          <div style={{ textAlign: 'center', padding: '20px', margin: "40px 0 20px" }}>
            <Spin size="large" />
            <p style={{ marginTop: '10px' }}>{rootResetBranch} 랭킹 점수를 초기화 중입니다. <br/> 이 작업은 1분 정도 소요됩니다.</p>
          </div>
        ) : (
          <>
            <p>
              정말로 모든 사용자의 점수를 초기화하시겠습니까? 이 작업은 되돌릴 수
              없습니다.
            </p>
            <p>
              초기화 지점 :{" "}
              {userInfo.isRootAdmin ? (
                <Select
                  style={{ width: 200 }}
                  onChange={(value) => setRootResetBranch(value)}
                  value={rootResetBranch}
                >
                  {branchOptions.map((option: any) => {
                    return (
                      <Option key={option.label} value={option.label}>
                        {option.label}
                      </Option>
                    );
                  })}
                  {/* <Option key={"테스트"} value={"테스트"}>
                    {"테스트"}
                  </Option> */}
                </Select>
              ) : (
                userInfo.branch
              )}
            </p>
            <div style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button onClick={() => setIsResetModalVisible(false)} style={{ marginRight: '10px' }}>
                취소
              </Button>
              <Button type="primary" onClick={handleResetOk} danger>
                초기화
              </Button>
            </div>
          </>
        )}
      </Modal>
    </Space>
  );
}