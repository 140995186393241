import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Typography,
    Space,
    Spin,
    Form,
    Input,
    Button,
    Select,
    message,
    Table,
    Modal,
    Card,
    Row,
    Col,
    Divider,
    Popconfirm,
    Tabs,
    Segmented,
} from "antd";
import {
    CalendarOutlined,
    TrophyOutlined,
    TeamOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    EnvironmentOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { LuParkingCircle } from "react-icons/lu";

import { branchOptions } from "../constants/branch";
import {
    addReservationToRally,
    cancelReservation,
    getRallyById,
    updateRally,
} from "../utils/rally";
import { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";
import { getUserById, updateUser } from "../utils/user";
import uuid from "react-uuid";
import moment from "moment";
import { AiOutlineMenu } from "react-icons/ai";
import { createReservationDeleteLog, createReservationLog } from "../utils/log";

const { Title, Text } = Typography;
const { Option } = Select;

const StyledCard = styled(Card)`
    margin-bottom: 24px;
    border: none;
    background: transparent;
    padding: 0;
    .ant-card-body {
        padding: 0;
    }
    .ant-card-head {
        padding: 0;
    }
    .ant-card-head-title {
        font-size: 18px;
        font-weight: bold;
    }
`;

const InfoItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    .anticon {
        font-size: 24px;
        margin-right: 16px;
        margin-top: 8px;
        color: #1890ff;
    }
`;

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledForm = styled(Form)`
    .ant-form-item-label {
        font-weight: bold;
    }
`;

const IdNumberInputGroup = styled.div`
    display: flex;
    align-items: center;
    .ant-input {
        flex: 1;
    }
    span {
        margin: 0 8px;
    }
`;

const PhoneInputGroup = styled.div`
    display: flex;
    align-items: center;
    .ant-input {
        flex: 1;
        text-align: center;
    }
    span {
        margin: 0 8px;
    }
`;
const StyledDivider = styled(Divider)`
    margin: 24px 0;
`;

const RESERVATION_DEADLINE = 1;
const CANCELLATION_DEADLINE = 5;
const RallyDetail: React.FC = () => {
    const [rally, setRally] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const { id } = useParams<{ id: string }>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [idNumberFront, setIdNumberFront] = useState("");
    const [idNumberBack, setIdNumberBack] = useState("");

    const [phoneFirst, setPhoneFirst] = useState("");
    const [phoneMiddle, setPhoneMiddle] = useState("");
    const [phoneLast, setPhoneLast] = useState("");
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

    const [userInfo, setUserInfo] = useRecoilState(userInfoState);
    const [canReserve, setCanReserve] = useState(false);
    const [canCancel, setCanCancel] = useState(false);
    const [activeSegment, setActiveSegment] = useState<string | number>(
        "confirmed"
    );

    const [isAlreadyRequested, setIsAlreadyRequested] = useState(false);
    const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);

    useEffect(() => {
        if (!rally?.reservations) return;
        for (const r of rally.reservations) {
            if (r.userId === userInfo.id) {
                setIsAlreadyRequested(true);
                return;
            }
        }
    }, [rally]);

    const navigate = useNavigate();

    useEffect(() => {
        if (rally) {
            const checkReservationPeriod = () => {
                const now = new Date();

                const rallyDate = new Date(`${rally.date}T${rally.time}`);

                // 예약 가능 기간: 대회 2시간 전까지
                const reservationDeadline = new Date(
                    rallyDate.getTime() - 2 * 60 * 60 * 1000
                );

                setCanReserve(now <= reservationDeadline);

                // 취소 가능 기간: 대회 5일 전까지
                const cancellationDeadline = new Date(
                    rallyDate.getTime() -
                        CANCELLATION_DEADLINE * 24 * 60 * 60 * 1000
                );
                setCanCancel(now <= cancellationDeadline);
            };

            checkReservationPeriod();
            const timer = setInterval(checkReservationPeriod, 60000); // 매 분마다 체크

            return () => clearInterval(timer);
        }
    }, [rally]);

    useEffect(() => {
        if (id) {
            fetchRallyDetail(id);
        }
    }, [id]);

    // useEffect(() => {
    //   if (rally) {
    //     const checkReservationPeriod = () => {
    //       const now = new Date();
    //       const start = new Date(
    //         `${rally.reservationStart}T${rally.reservationStartTime}`
    //       );
    //       const end = new Date(
    //         `${rally.reservationEnd}T${rally.reservationEndTime}`
    //       );
    //       setIsReservationPeriod(now >= start && now <= end);
    //     };

    //     checkReservationPeriod();
    //     const timer = setInterval(checkReservationPeriod, 60000); // 매 분마다 체크

    //     return () => clearInterval(timer);
    //   }
    // }, [rally]);

    const fetchRallyDetail = async (rallyId: string) => {
        setLoading(true);
        try {
            const rallyData = await getRallyById(rallyId);
            if (rallyData) {
                setRally(rallyData);
            } else {
                message.error("대회를 찾을 수 없습니다.");
            }
        } catch (error) {
            console.error("Error fetching rally detail:", error);
            message.error("대회 정보를 불러오는데 실패했습니다.");
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const onFinish = async (values: any) => {
        if (!rally) return;

        if (!idNumberFront || !idNumberBack) {
            message.warning("주민등록번호를 입력해주세요");
            return;
        }
        if (userInfo.coin < rally.coinFee) {
            message.warning("통합포인트(DXG)가 부족합니다.");
            return;
        }
        const fullIdNumber = `${idNumberFront}-${idNumberBack}`;
        const fullPhone = `${phoneFirst}-${phoneMiddle}-${phoneLast}`;
        const reservation: any = {
            ...values,
            name: userInfo.username,
            id: uuid(),
            idNumber: fullIdNumber,
            userId: userInfo.id,
            phone: userInfo?.phone || "-",
            createdAt: new Date().toISOString(),
        };

        try {
            await addReservationToRally(rally.id, reservation);

            const updatedUser = {
                ...userInfo,
                coin: parseInt(userInfo.coin) - parseInt(rally.coinFee),
            };

            await updateUser(updatedUser, userInfo, true, "대회등록");
            await createReservationLog(
                userInfo.id,
                userInfo.branch,
                userInfo.username,
                userInfo.nickname,
                rally.name,
                userInfo.username
            );
            setUserInfo(updatedUser);
            message.success("예약이 완료되었습니다!");
            form.resetFields();
            setIdNumberFront("");
            setIdNumberBack("");
            setPhoneFirst("");
            setPhoneMiddle("");
            setPhoneLast("");
            setIsModalVisible(false);
            fetchRallyDetail(rally.id);
        } catch (error) {
            console.error("Error adding reservation:", error);
            message.error("예약 중 오류가 발생했습니다. 다시 시도해 주세요.");
        }
    };

    const handleCancelReservation = async (reservationId: string) => {
        if (!rally) return;

        try {
            await cancelReservation(rally.id, reservationId);

            // 포인트 환불
            const updatedUser = {
                ...userInfo,
                coin: parseInt(userInfo.coin) + parseInt(rally.coinFee),
            };

            await updateUser(updatedUser, userInfo, true, "대회취소");
            await createReservationDeleteLog(
                userInfo.id,
                userInfo.branch,
                userInfo.username,
                userInfo.nickname,
                rally.name,
                userInfo.username
            );

            setUserInfo(updatedUser);

            message.success("예약이 취소되었고, 포인트가 환불되었습니다.");
            fetchRallyDetail(rally.id);
        } catch (error) {
            console.error("Error canceling reservation:", error);
            message.error(
                "예약 취소 중 오류가 발생했습니다. 다시 시도해 주세요."
            );
        }
    };

    const columns = [
        {
            title: "이름",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "휴대폰번호",
            dataIndex: "phone",
            key: "phone",
            render: (v: string) => {
                if (!v) return "-";
                const p = v.split("-");

                return p
                    ? `${p[0]}-****-*${p[2] ? p[2].slice(1, 4) : "-"}`
                    : "-";
            },
        },
        {
            title: "지점",
            dataIndex: "locationId",
            key: "locationId",
        },
        {
            title: "신청 일시",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: string) => formatDate(text),
        },
        // ...(userInfo.isAdmin || userInfo.isRootAdmin
        //   ? [
        //       {
        //         title: "주민등록번호",
        //         dataIndex: "idNumber",
        //         key: "idNumber",
        //         render: (text: string) => text + "******",
        //       },
        //     ]
        //   : []),
    ];

    const waitlistColumns = [
        {
            title: "대기 순번",
            dataIndex: "waitlistNumber",
            key: "waitlistNumber",
            render: (text: string, record: any, index: number) => index + 1,
        },
        ...columns,
    ];

    const calculateReservationEndDate = (
        rallyDate: string,
        rallyTime: string
    ) => {
        const date = new Date(`${rallyDate}T${rallyTime}`);
        console.log(date.getHours());
        date.setHours(date.getHours() - RESERVATION_DEADLINE * 2);
        return date.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const reservationEndDate = calculateReservationEndDate(
        rally?.date,
        rally?.time
    );

    const calculateReservationCancelDate = (
        rallyDate: string,
        rallyTime: string
    ) => {
        const date = new Date(`${rallyDate}T${rallyTime}`);
        console.log(date.getHours());
        date.setHours(date.getHours() - CANCELLATION_DEADLINE * 24);
        return date.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const reservationCancelDate = calculateReservationCancelDate(
        rally?.date,
        rally?.time
    );

    const myReservationColumns = [
        ...columns,
        {
            title: "작업",
            dataIndex: "id",
            key: "action",
            render: (id: string) => (
                <Popconfirm
                    title="예약을 취소하시겠습니까?"
                    onConfirm={() => handleCancelReservation(id)}
                    okText="예"
                    cancelText="아니오"
                    disabled={!canCancel}
                >
                    <Button disabled={!canCancel} size="small">
                        취소하기
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    const handleRefundAllWaitlist = async () => {
        try {
            console.log(waitlistReservations);

            for (const reservation of waitlistReservations) {
                const user = await getUserById(reservation.userId);

                const updatedUser = {
                    ...user,
                    coin: parseInt(user.coin) + parseInt(rally.coinFee),
                };

                const res = await updateUser(
                    updatedUser,
                    userInfo,
                    true,
                    "대회취소"
                );

                await createReservationDeleteLog(
                    user.id,
                    user.branch,
                    user.username,
                    user.nickname,
                    rally.name,
                    userInfo.username
                );
            }

            const waitIdList = waitlistReservations.map((r: any) => r.id);

            console.log(waitIdList);
            const updatedReservations = rally.reservations?.filter(
                (reservation: any) => !waitIdList.includes(reservation.id)
            );

            const updatedRally = {
                ...rally,
                reservations: updatedReservations,
                gsiPartitionKey: "ALL",
            };
            await updateRally(updatedRally);

            // await refundAllWaitlist(rally.id);
            message.success("모든 대기자의 환불이 완료되었습니다.");
            fetchRallyDetail(rally.id);
        } catch (error) {
            console.error("Error refunding all waitlist:", error);
            message.error(
                "대기자 전체 환불 중 오류가 발생했습니다. 다시 시도해 주세요."
            );
        }
        setIsRefundModalVisible(false);
    };

    if (loading) {
        return <Spin size="large" style={{ margin: "auto" }} />;
    }

    if (!rally) {
        return <Title level={3}>대회를 찾을 수 없습니다.</Title>;
    }

    
    const confirmedReservations = rally.reservations
        ? rally.reservations.slice(
              0,
              rally.isNoLimit ? rally.reservations.length : rally.maximumPeople
          )
        : [];

    const waitlistReservations =
        rally.reservations && !rally.isNoLimit
            ? rally.reservations.slice(rally.maximumPeople)
            : [];

    const myReservations =
        rally.reservations?.filter(
            (reservation: any) => reservation.userId === userInfo.id
        ) || [];

    console.log(confirmedReservations);

    return (
        <Space
            direction="vertical"
            size="middle"
            style={{
                display: "flex",
                padding: "24px",
                width: "100%",
                maxWidth: 1200,
                boxSizing: "border-box",
                margin: "0 auto",
            }}
        >
            <StyledCard>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 20,
                    }}
                >
                    <Title level={2} style={{ margin: 0 }}>
                        {rally.name}
                    </Title>
                    <Button
                        style={{ padding: 3, marginRight: 20 }}
                        type="text"
                        onClick={() => navigate("/rally")}
                    >
                        <AiOutlineMenu size={30} />
                    </Button>
                </div>

                <StyledDivider />
                <Row gutter={[24, 24]}>
                    {[
                        {
                            icon: <CalendarOutlined />,
                            title: "대회 일시",
                            content: formatDate(`${rally.date}T${rally.time}`),
                        },
                        {
                            icon: <TrophyOutlined />,
                            title: "상금",
                            content: `${parseInt(
                                rally.prizeMoney
                            )?.toLocaleString()} GTD${
                                rally.plusPrizeMoney ? "+" : ""
                            }`,
                        },
                        {
                            icon: <TeamOutlined />,
                            title: "최대 참가 인원",
                            content: `${rally.maximumPeople}${
                                rally.isNoLimit ? "+" : ""
                            } 명`,
                        },
                        {
                            icon: <ClockCircleOutlined />,
                            title: "예약 가능 기간",
                            content: `~${reservationEndDate}`,
                        },
                        {
                            icon: <ClockCircleOutlined />,
                            title: "예약 취소 기간",
                            content: `~${reservationCancelDate}`,
                        },
                        {
                            icon: (
                                <LuParkingCircle
                                    size={24}
                                    color="#1890ff"
                                    style={{ marginRight: 14 }}
                                />
                            ),
                            title: "등록포인트",
                            content: `${rally.coinFee} DXG`,
                        },
                        {
                            icon: <EnvironmentOutlined />,
                            title: "대회 장소",
                            content: rally.place,
                        },
                    ].map((item, index) => (
                        <Col key={index} span={isMobile ? 24 : 12}>
                            <InfoItem>
                                {item.icon}
                                <InfoContent>
                                    <Text strong>{item.title}</Text>
                                    <Text style={{ color: "#777" }}>
                                        {item.content}
                                    </Text>
                                </InfoContent>
                            </InfoItem>
                        </Col>
                    ))}
                </Row>
            </StyledCard>

            <StyledCard
                title={`신청 현황 (${confirmedReservations.length} / ${
                    rally.maximumPeople
                }${rally.isNoLimit ? "+" : ""})`}
                extra={
                    <Button
                        onClick={() => setIsModalVisible(true)}
                        disabled={!canReserve || isAlreadyRequested}
                    >
                        대회 신청하기
                    </Button>
                }
            >
                <Segmented
                    options={[
                        { label: "확정 명단", value: "confirmed" },
                        {
                            label: `대기 명단 (${waitlistReservations.length})`,
                            value: "waitlist",
                        },
                    ]}
                    value={activeSegment}
                    onChange={setActiveSegment}
                    style={{ marginBottom: 16, marginTop: 16 }}
                />
                {activeSegment === "confirmed" ? (
                    <Table
                        dataSource={confirmedReservations}
                        columns={columns}
                        rowKey="createdAt"
                        pagination={false}
                        size="middle"
                        scroll={{ x: 500 }}
                    />
                ) : (
                    <div style={{ position: "relative" }}>
                        {userInfo.isRootAdmin && (
                            <Button
                                style={{
                                    marginBottom: 10,
                                    position: "absolute",
                                    right: 0,
                                    top: -48,
                                }}
                                onClick={() => setIsRefundModalVisible(true)}
                                disabled={waitlistReservations.length === 0}
                            >
                                대기자 전체 환불
                            </Button>
                        )}
                        <Table
                            dataSource={waitlistReservations}
                            columns={waitlistColumns}
                            rowKey="createdAt"
                            pagination={false}
                            size="middle"
                            scroll={{ x: 700 }}
                        />
                    </div>
                )}
            </StyledCard>

            {myReservations.length > 0 && (
                <StyledCard title="나의 등록 현황" extra={<></>}>
                    <Table
                        dataSource={myReservations}
                        columns={myReservationColumns}
                        rowKey="createdAt"
                        pagination={false}
                        size="middle"
                        style={{ marginTop: 20 }}
                        scroll={{ x: 700 }}
                    />
                </StyledCard>
            )}

            <Modal
                title="대회 신청"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={600}
            >
                <StyledForm form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item name="name" label="성명">
                        <Input defaultValue={userInfo.username} disabled />
                    </Form.Item>
                    <Form.Item label="연락처">
                        <PhoneInputGroup>
                            <Input
                                onChange={(e) => setPhoneFirst(e.target.value)}
                                maxLength={3}
                                disabled
                                defaultValue={
                                    userInfo?.phone
                                        ? userInfo.phone.split("-")[0]
                                        : "-"
                                }
                            />
                            <span>-</span>
                            <Input
                                onChange={(e) => setPhoneMiddle(e.target.value)}
                                maxLength={4}
                                disabled
                                defaultValue={
                                    userInfo?.phone
                                        ? userInfo.phone.split("-")[1]
                                        : "-"
                                }
                            />
                            <span>-</span>
                            <Input
                                onChange={(e) => setPhoneLast(e.target.value)}
                                maxLength={4}
                                disabled
                                defaultValue={
                                    userInfo?.phone
                                        ? userInfo.phone.split("-")[2]
                                        : "-"
                                }
                            />
                        </PhoneInputGroup>
                    </Form.Item>
                    <Form.Item
                        label="주민등록번호"
                        required
                        rules={[
                            {
                                required: true,
                                message: "주민등록번호를 입력해주세요",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!idNumberFront || !idNumberBack) {
                                        return Promise.reject(
                                            "주민등록번호를 입력해주세요"
                                        );
                                    }
                                    if (
                                        !/^\d{6}$/.test(idNumberFront) ||
                                        !/^\d{1}$/.test(idNumberBack)
                                    ) {
                                        return Promise.reject(
                                            "올바른 형식으로 입력해주세요"
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <IdNumberInputGroup>
                            <Input
                                value={idNumberFront}
                                onChange={(e) =>
                                    setIdNumberFront(e.target.value)
                                }
                                maxLength={6}
                                placeholder="앞 6자리"
                                style={{ maxWidth: 90 }}
                            />
                            <span>-</span>
                            <Input
                                value={idNumberBack}
                                onChange={(e) =>
                                    setIdNumberBack(e.target.value)
                                }
                                maxLength={1}
                                placeholder="1"
                                suffix="******"
                                style={{ maxWidth: 90 }}
                            />
                        </IdNumberInputGroup>
                    </Form.Item>

                    <Form.Item
                        name="locationId"
                        label="지점 선택"
                        rules={[
                            { required: true, message: "지점을 선택해주세요" },
                        ]}
                    >
                        <Select placeholder="지점을 선택해주세요">
                            {branchOptions.map((location) => (
                                <Option
                                    key={location.label}
                                    value={location.label}
                                >
                                    {location.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" style={{ width: "100%" }}>
                            신청하기
                        </Button>
                    </Form.Item>
                </StyledForm>
            </Modal>
            <Modal
                title="대기자 전체 환불"
                open={isRefundModalVisible}
                onOk={handleRefundAllWaitlist}
                onCancel={() => setIsRefundModalVisible(false)}
                okText="확인"
                cancelText="취소"
            >
                <p>
                    정말로 모든 대기자의 통합포인트를 환불하시겠습니까? 이
                    작업은 취소할 수 없습니다.
                </p>
            </Modal>
        </Space>
    );
};

export default RallyDetail;
