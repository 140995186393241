import React, { useEffect, useState } from 'react';
import { List, Typography, Space, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CalendarOutlined, TeamOutlined, TrophyOutlined } from '@ant-design/icons';
import { getAllRallies } from '../utils/rally';

const { Title, Text } = Typography;

export default function Rally() {
  const [rallies, setRallies] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRallies();
  }, []);

  const fetchRallies = async () => {
    setLoading(true);
    try {
      const result = await getAllRallies();
      setRallies(result);
    } catch (error) {
      console.error("Failed to fetch rallies:", error);
      message.error("대회 목록을 불러오는데 실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleRallyClick = (id: string) => {
    navigate(`/rally/${id}`);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  console.log(rallies)
  return (
    <Space direction="vertical" size="large" style={{ display: 'flex', padding: '24px', width: "100%", maxWidth: 1200, margin: "0 auto", boxSizing: "border-box" }}>
      <Title level={2}>대회 목록</Title>
      <List
        itemLayout="horizontal"
        dataSource={rallies}
        renderItem={(item) => (
          <List.Item 
            key={item.id}
            onClick={() => handleRallyClick(item.id)}
            style={{ cursor: 'pointer', padding: '18px 5px', transition: 'background-color 0.3s', borderBottom: "1px solid #3c3c3c" }}
            className="hover:bg-gray-100"
          >
            <List.Item.Meta

              title={<Text strong style={{ fontSize: 18}}>{item.name}</Text>}
              description={
                <Space direction="vertical">
                  <Text color='#777' style={{ color: "#999"}}><CalendarOutlined style={{ marginRight: 5}} /> 대회 일시: {formatDate(`${item.date}T${item.time}`)}</Text>
                  <Text color='#777' style={{ color: "#999"}}><TeamOutlined style={{ marginRight: 5}} /> 참가자: {item.reservations ? item.reservations.length : 0} / {item.maximumPeople}{item.isNoLimit && "+"}</Text>
                  <Text color='#777' style={{ color: "#999"}}><TrophyOutlined  style={{ marginRight: 5}} /> 상금: {parseInt(item.prizeMoney).toLocaleString()} GTD{item.plusPrizeMoney && "+"}</Text>
                  <Text color='#777' style={{ color: "#999"}}><TrophyOutlined  style={{ marginRight: 5}} /> 등록 포인트: {parseInt(item.coinFee).toLocaleString()} DXG</Text>
                  <Text color='#777' style={{ color: "#999"}}><TrophyOutlined  style={{ marginRight: 5}} /> 대회 장소: {item.place}</Text>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </Space>
  );
}